// https://callstack.github.io/react-native-paper/theming.html
export const lightColors = {
  primary: 'rgb(0, 95, 175)',
  onPrimary: 'rgb(255, 255, 255)',
  primaryContainer: 'rgb(212, 227, 255)',
  onPrimaryContainer: 'rgb(0, 28, 58)',
  secondary: 'rgb(84, 95, 113)',
  onSecondary: 'rgb(255, 255, 255)',
  secondaryContainer: 'rgb(216, 227, 248)',
  onSecondaryContainer: 'rgb(17, 28, 43)',
  tertiary: 'rgb(71, 85, 182)',
  onTertiary: 'rgb(255, 255, 255)',
  tertiaryContainer: 'rgb(223, 224, 255)',
  onTertiaryContainer: 'rgb(0, 13, 95)',
  error: 'rgb(186, 26, 26)',
  onError: 'rgb(255, 255, 255)',
  errorContainer: 'rgb(255, 218, 214)',
  onErrorContainer: 'rgb(65, 0, 2)',
  background: 'rgb(253, 252, 255)',
  onBackground: 'rgb(26, 28, 30)',
  surface: 'rgb(253, 252, 255)',
  onSurface: 'rgb(26, 28, 30)',
  surfaceVariant: 'rgb(224, 226, 236)',
  onSurfaceVariant: 'rgb(67, 71, 78)',
  outline: 'rgb(116, 119, 127)',
  outlineVariant: 'rgb(195, 198, 207)',
  shadow: 'rgb(0, 0, 0)',
  scrim: 'rgb(0, 0, 0)',
  inverseSurface: 'rgb(47, 48, 51)',
  inverseOnSurface: 'rgb(241, 240, 244)',
  inversePrimary: 'rgb(165, 200, 255)',
  elevation: {
    level0: 'transparent',
    level1: 'rgb(240, 244, 251)',
    level2: 'rgb(233, 239, 249)',
    level3: 'rgb(225, 235, 246)',
    level4: 'rgb(223, 233, 245)',
    level5: 'rgb(218, 230, 244)',
  },
  surfaceDisabled: 'rgba(26, 28, 30, 0.12)',
  onSurfaceDisabled: 'rgba(26, 28, 30, 0.38)',
  backdrop: 'rgba(45, 49, 56, 0.4)',
};

export const darkColors = {
  primary: 'rgb(182, 196, 255)',
  onPrimary: 'rgb(0, 39, 127)',
  primaryContainer: 'rgb(33, 63, 153)',
  onPrimaryContainer: 'rgb(220, 225, 255)',
  secondary: 'rgb(182, 196, 255)',
  onSecondary: 'rgb(0, 39, 127)',
  secondaryContainer: 'rgb(51,54,69)',
  onSecondaryContainer: 'rgb(220, 225, 255)',
  tertiary: 'rgb(197, 192, 255)',
  onTertiary: 'rgb(43, 34, 119)',
  tertiaryContainer: 'rgb(66, 59, 142)',
  onTertiaryContainer: 'rgb(227, 223, 255)',
  error: 'rgb(255, 180, 171)',
  onError: 'rgb(105, 0, 5)',
  errorContainer: 'rgb(101,46,49)',
  onErrorContainer: 'rgb(255, 180, 171)',
  background: 'rgb(27, 27, 31)',
  onBackground: 'rgb(228, 225, 230)',
  surface: 'rgb(27, 27, 31)',
  onSurface: 'rgb(228, 225, 230)',
  surfaceVariant: 'rgb(69, 70, 79)',
  onSurfaceVariant: 'rgb(198, 197, 208)',
  outline: 'rgb(144, 144, 154)',
  outlineVariant: 'rgb(69, 70, 79)',
  shadow: 'rgb(0, 0, 0)',
  scrim: 'rgb(0, 0, 0)',
  inverseSurface: 'rgb(228, 225, 230)',
  inverseOnSurface: 'rgb(48, 48, 52)',
  inversePrimary: 'rgb(61, 88, 179)',
  elevation: {
    level0: 'transparent',
    level1: 'rgb(35, 35, 42)',
    level2: 'rgb(39, 41, 49)',
    level3: 'rgb(44, 46, 56)',
    level4: 'rgb(46, 47, 58)',
    level5: 'rgb(49, 51, 62)',
  },
  surfaceDisabled: 'rgba(228, 225, 230, 0.12)',
  onSurfaceDisabled: 'rgba(228, 225, 230, 0.38)',
  backdrop: 'rgba(47, 48, 56, 0.4)',
};
